import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import { GetPostRequest } from 'src/app/core/interfaces/blog/GetPostRequest';
import { GetPostByUrlName } from 'src/app/core/interfaces/blog/GetPostByUrlName';

@Injectable({
    providedIn: 'root'
  })
  export class BlogService{
  
    constructor(private http: HttpClient) {
      
    }

    GetPosts(body: GetPostRequest) : Observable<any> {
        const url = environment.apiGetBlogs;
        return this.http.post(url, body);
    }

    GetPostByUrlName(body: GetPostByUrlName) : Observable<any> {
      const url = environment.apiGetPostByUrlName;
      return this.http.post(url, body);
    }
}